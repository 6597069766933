// 调试时候引入，打包需要注释
@import "antd-default.dev";

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #eef0f7 !important;
}

.ant-form-item-label > label::after {
  margin-right: 20px !important;
}
.ant-table-pagination.ant-pagination{
  margin-bottom: 0 !important;
}
