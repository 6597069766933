.imur-action-bar {
  .ant-btn {
    font-size: 12px;
    width: 104px;
    height: 30px;
    margin-left: @margin-xs;
    &:first-of-type {
      margin-left: 0;
    }
  }
  .ant-btn > .anticon + span {
    margin-left: @margin-xss;
  }
}

.imur-table-operation {
  button:first-of-type {
    padding-left: 0;
  }
}
